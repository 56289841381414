import styles from '@/styles/components/modal/ModalCustom.module.scss'
import { useState } from 'react'
import { CrossBtn } from '../controls/CrossBtn'

interface Props {
  show: boolean,
  maxWidth?: string,
  style?: any,
  multiopen?: boolean,
  className?: string,
  onClose?: () => void
}

export const Modal:  React.FC<Props> = ({ show, onClose, className = '', children, style = {}, maxWidth = '650px', multiopen = false }) => {
  const [closed, setClosed] = useState(false)

  const canOpenAgain = () => {
    return multiopen ? true : closed ? false : true
  }

  return (
    <>
      {show && canOpenAgain() && <div className={`${className} ${styles.modal_container}`}>
        <div className={styles.modal_bg}></div>
        <div className={`${styles.modal} modal-container`} style={Object.assign({maxWidth: maxWidth}, style)}>
          <CrossBtn className="modal__close-btn modal__close-btn-3" color="black" onClick={() => {
            setClosed(true)
            onClose && onClose()
          }}/>
          { children }
        </div>
      </div>}
    </>
  )
}
