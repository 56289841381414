import { Headline2 } from '@/components/common/Headline2'
import Image from 'next/image'
import { imageLoader } from '@/loaders/index'
import dynamic from 'next/dynamic'

import problems from '@/public/images/problems.png'
import features from '@/public/images/features.png'
import benefits from '@/public/images/benefits.png'

import { emitter } from '@/utils/emitter'
import { useState } from 'react'
import { useBetterMediaQuery } from '@/hooks/BetterMediaQuery'
import Link from 'next/link'
import { useQuery } from '@/utils/url'

const DynamicHardScienceOffcanvas = dynamic( // @ts-ignore
  () => import('@/components/offcanvas/HardScienceOffcanvas').then((mod) => mod.HardScienceOffcanvas),
  { ssr: false }
)

export const WhySection4: React.FC = () => {
  const [show, setShow] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const lg = useBetterMediaQuery('(min-width: 1024px)')

  const closeHandler = () => {
    setShow(false)

    emitter.emit('hard-science-offcanvas-closed')
  }

  const showHandler = (e: any) => {
    e.stopPropagation()
    setLoaded(true)
    setShow(true)

    emitter.emit('hard-science-offcanvas-opened')
  }

  return (
    <section className={`${lg ? 'pt-70-to-130': ''}`}>
      {loaded && <DynamicHardScienceOffcanvas show={show} close={closeHandler} />}
      <div className="container container--sec container--max-w-1111">
        <div className="flex flex-col md:flex-row justify-around items-center pb-70-to-130">
          <div className="md:w-[40%] shrink-0 pb-[70px] md:pb-0">
            <Headline2 tag="h2" className="text-20-to-30 mb-30-to-40 font-semibold" weight={800}>Tired Of Spinning Your Wheels?</Headline2>
            <p className="text-18-to-20 mb-[30px]">If you’re anything like the entrepreneurs we help, you know you could be moving faster. But no matter how hard you work, you always feel behind.</p>
            <p className="text-18-to-20 mb-[30px]">Sadly, most people turn to the same old solutions: woo-woo self-help books, expensive courses from gurus, or complicated project management tools.</p>
            <p className="text-18-to-20 mb-[30px]">But it never works, because every other productivity solution just puts more on your plate…</p>
            <p className="text-18-to-20">They demand more will power, more learning time, and more mental effort, and so they always fail after the initial excitement wears off.</p>
          </div>
          <div className="md:w-[35%]">
            <Image loader={imageLoader} src={problems} alt="Problems" layout="intrinsic" />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-around items-center pb-70-to-130">
          <div className="md:w-[39%] shrink-0 pb-[70px] md:pb-0 md:order-1">
            <Headline2 tag="h2" className="text-20-to-30 mb-30-to-40 font-semibold" weight={800}>A New Approach To Productivity</Headline2>
            <p className="text-18-to-20 mb-[30px]">We believe you already have all the ideas.</p>
            <p className="text-18-to-20 mb-[30px]">You just struggle to take action, because entrepreneurs lack structure and daily accountability.</p>
            <p className="text-18-to-20 mb-[30px] font-bold"><em>We solve this overnight by installing an evidence-based weekly ritual that becomes an accelerator for everything else in your life.</em></p>
            <p className="text-18-to-20 mb-[30px]">The ritual was developed with researchers at Harvard and NYU, so it’s based on real <span className="link" onClick={showHandler}>science</span>.</p>
            <p className="text-18-to-20">And, it’s incredibly easy to stick to it consistently, because you’re guided through it every week 1-to-1, over the phone, by an expert accountability coach (all in-house & US-based).</p>
          </div>
          <div className="md:w-[35%]">
            <Image loader={imageLoader} src={features} alt="Commit Action Features" layout="intrinsic" />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-around items-center pb-70-to-130">
          <div className="md:w-[40%] shrink-0 pb-[70px] md:pb-0">
            <Headline2 tag="h2" className="text-20-to-30 mb-30-to-40 font-semibold" weight={800}>Become The Most Effective Entrepreneur In Your Circle</Headline2>
            <p className="text-18-to-20 mb-[30px]">Getting clarity on your most important work and applying the <span className="link" onClick={showHandler}>science of productivity</span> to your plans each week gives you leverage, and produces compounding results.</p>
            <p className="text-18-to-20 mb-[30px]">You start consistently working ON your business, not just IN it. And hitting your biggest goals, without clocking more hours at your desk, or sacrificing your freedom and control.</p>
            <p className="text-18-to-20">We’ve helped over thousands of entrepreneurs achieve all kinds of <Link target={'_blank'} href={useQuery('/success-stories')} passHref><a target={'_blank'} className="link">incredible life-changing goals</a></Link> with our accountability coaching program.</p>
          </div>
          <div className="md:w-[35%]">
            <Image loader={imageLoader} src={benefits} alt="Commit Action Benefits" layout="intrinsic" />
          </div>
        </div>
      </div>
      {loaded && <DynamicHardScienceOffcanvas show={show} close={closeHandler} />}
    </section>
  )
}
