import { Headline2 } from '@/components/common/Headline2'
import { imageLoader } from '@/loaders/index'
import Image from 'next/image'
import { createRef, forwardRef, MutableRefObject, useEffect, useRef, useState } from 'react'
import dynamic from 'next/dynamic'
import { LottieRefCurrentProps } from 'lottie-react'
import { useIntersections } from '@/hooks/Intersections'
import { usePinable } from '@/hooks/Pinable'

import howitworks5 from '@/public/images/how-it-works-5-min.jpg'
import ears from '@/public/images/ears.png'
import mic from '@/public/images/mic.png'
import smile from '@/public/images/smile.png'
import { useMediaQuery } from 'react-responsive'

const numberOfRows = 4

const DynamicAnimation = dynamic( // @ts-ignore
  () => import('@/components/dynamic/DynamicAnimation').then((mod) => mod.DynamicAnimation),
  { ssr: false }
)

type Props = {
  name: string
}

const DynamicAnimationWithRef = forwardRef((props: Props, ref) => (
  // @ts-ignore
  <DynamicAnimation {...props} forwardedRef={ref} />
))

DynamicAnimationWithRef.displayName = 'DynamicAnimationWithRef'

export const BonusesSection: React.FC = () => {
  const sm = useMediaQuery({minWidth: 600})
  const rowRefs = useRef<MutableRefObject<HTMLDivElement | null>[]>([])
  const lottieRefs = useRef<MutableRefObject<LottieRefCurrentProps | null>[]>([])
  const [animsLoaded, setAnimsLoaded] = useState(false)
  const [animsToLoad, setAnimsToLoad] = useState([0, 0, 0, 0])
  const intersections = useIntersections(rowRefs.current, {
    threshold: 0.5
  })

  const isOffsetReached = usePinable(5)

  const buildRefs = () => {
    for (let i = 0; i < numberOfRows; i++) {
      lottieRefs.current[i] = lottieRefs.current[i] || createRef()
      rowRefs.current[i] = rowRefs.current[i] || createRef()
    }
  }

  useEffect(() => {
    buildRefs()

    return () => {
      for (let i = 0; i < numberOfRows; i++) {
        lottieRefs.current[i].current?.destroy()
        rowRefs.current[i].current = null
      }
    }
  }, [])

  useEffect(() => {
    // For a performance reasons we need to run animations components with delay
    if (isOffsetReached && !animsLoaded) {
      setAnimsToLoad([1, 0, 0, 0])

      setTimeout(() => {
        setAnimsToLoad([1, 1, 0, 0])
      }, 600)

      setTimeout(() => {
        setAnimsToLoad([1, 1, 1, 0])
      }, 1500)

      setTimeout(() => {
        setAnimsToLoad([1, 1, 1, 1])
        setAnimsLoaded(true)
      }, 2000)
    }
  }, [isOffsetReached])

  useEffect(() => {
    if (!intersections.length) return

    intersections.forEach((intersection, i) => {
      if (intersection) {
        lottieRefs.current[i].current?.play()
      } else {
        lottieRefs.current[i].current?.pause()
      }
    })
  })

  return (
    <section className="pt-70-to-130">
      <div className="container container--sec container--max-w-1111">
        <Headline2 tag="h2" className="text-center text-24-to-38 mb-[40px] lg:mb-[80px]">Bonuses</Headline2>
        <div className="flex flex-col md:flex-row justify-between items-center pb-70-to-130">
          <div className="md:w-[45%] shrink-0 md:pb-0">
            <Headline2 tag="h3" className="text-18-to-22 text-center md:text-left mb-30-to-40 font-semibold" weight={800}><em className='font--primary'>Bonus 1:</em> Strategic Planning Call</Headline2>
            {!sm && <div className="md:w-[45%]">
              { animsToLoad[0] && <DynamicAnimationWithRef ref={lottieRefs.current[0]} name="how-it-works-1" /> }
            </div>}
            <p className="text-18-to-20 mb-[30px]">These hour-long deep dives help us get to know you and your business in detail, map out all your most important projects, and create a step-by-step plan for getting them done.</p>
            <p className="text-18-to-20">You’ll be able to book one of these in as soon as you join, and then you’ll go through it again every quarter so you have constant clarity on your goals.</p>
          </div>
          {sm && <div className="md:w-[40%]">
            { animsToLoad[0] && <DynamicAnimationWithRef ref={lottieRefs.current[0]} name="how-it-works-1" /> }
          </div>}
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center pb-70-to-130">
          <div className="md:w-[45%] shrink-0 md:pb-0">
            <Headline2 tag="h3" className="text-18-to-22 text-center md:text-left mb-30-to-40 font-semibold" weight={800}><em className='font--primary'>Bonus 2:</em> Learn the Psychology of Entrepreneurship</Headline2>
            {!sm &&<div className="md:w-[45%]">
              <Image loader={imageLoader} src={howitworks5} alt="Training You Won’t Find Anywhere Else" layout="intrinsic" />
            </div>}
            <p className="text-18-to-20 mb-[30px]">As a perk of Commit Action membership, you’ll get access to lots of member exclusive content:</p>
            <div className='ml-[20px]'>
              <p className="text-[16px] mb-[30px] flex">
                <div className='w-[19px] h-[24px] shrink-0 mr-[8px]'>
                  <Image loader={imageLoader} src={mic} layout="intrinsic" alt="Mic icon"/>
                </div>
                Quarterly workshops to keep you organized and on-top of your goals, year round
              </p>
              <p className="text-[16px] mb-[30px] flex">
                <div className='w-[19px] h-[24px] shrink-0 mr-[8px]'>
                  <Image loader={imageLoader} src={ears} layout="intrinsic" alt="Ears icon"/>
                </div>
                Monthly live events on everything from the science of habit building to overcoming imposter syndrome.
              </p>
              <p className="text-[16px] mb-[30px] flex">
                <div className='w-[19px] h-[24px] shrink-0 mr-[8px]'>
                  <Image loader={imageLoader} src={smile} layout="intrinsic"alt="Smile icon" />
                </div>
                Access to our full video library back catalogue (featuring guests like Seth Godin & Nir Eyal!)
              </p>
            </div>
          </div>
          {sm &&<div className="md:w-[40%]">
            <Image loader={imageLoader} src={howitworks5} alt="Training You Won’t Find Anywhere Else" layout="intrinsic" />
          </div>}
        </div>
      </div>
    </section>
  )
}
